import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Breadcrumbs = (props) => {
    const {
        children,
        className,
        themeColor = true,
        ...rest
    } = props

    const classNameProps = {
        className: classNames(
            themeColor && 'text-beaconMain-600 flex flex-row gap-2',
            className
        ),
    }

    return (
      <div {...classNameProps} {...rest}>
        {children}
      </div>
    )
}

Breadcrumbs.propTypes = {
    themeColor: PropTypes.bool,
}

Breadcrumbs.defaultProps = {
    themeColor: true,
}

export default Breadcrumbs
