import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
// import { apiActivate, apiGetProfile, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { AccessToken, REDIRECT_URL_KEY, RefreshToken } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

import { authApi } from 'api/auth'
import { profileApi } from 'api/profile'

import { useAuth0 } from '@auth0/auth0-react'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const { isAuthenticated, logout, loginWithRedirect } = useAuth0()

    const signInWithAuth0 = async () => {
        await loginWithRedirect()
    }

    const signIn = async (values) => {
        try {
            const resp = await authApi.login(values)
            if (resp.data) {
                const { access, refresh } = resp.data                      
                
                // store access token to localStorage
                localStorage.setItem(AccessToken, access)
                localStorage.setItem(RefreshToken, refresh)
                
                dispatch(onSignInSuccess(access))
                const profileRes = await profileApi.getMe();
                // console.log(profileRes.data)
                if(profileRes.data) {
                    localStorage.setItem('userData', JSON.stringify(profileRes.data))
                    dispatch(
                        setUser(
                            profileRes.data || {
                                first_name: "",
                                last_name: "",
                                id: 0,
                                username: "user@example.com",
                                phone_number: "",
                                address_1: "",
                                address_2: "",
                                city: "",
                                state: "",
                                zip_code: "",
                            }
                        )
                    )                                  
                }
                
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {            
            return {
                status: 'failed',
                message: errors?.response?.data?.detail || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await authApi.register(values)
            if (resp.data) {                               
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.username || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
        logout({ returnTo: window.location.origin })
    }

    const signOut = async () => {
        localStorage.removeItem(AccessToken)
        localStorage.removeItem(RefreshToken)
        localStorage.removeItem('userData')
        await authApi.logout()
        handleSignOut()
    }

    const Activate = async (values) => {
        try {
            const resp = await authApi.verify(values)            
            if (resp.status === 204) {                                               
                navigate('/success')
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.detail || errors.toString(),
            }
        }
    }

    return {
        authenticated: token && signedIn || isAuthenticated,
        signIn,
        signInWithAuth0,
        signUp,
        signOut,
        Activate,
    }
}

export default useAuth
