import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'components/ui'
import { HomeRegularIcon } from 'components/icons/HomeRegularIcon'
import { ArrowRightIcon } from 'components/icons/ArrowRightIcon'

const BreadcrumbItem = (props) => {
    const {
        children,
        className,
        themeColor = true,
        to,
        href = '',
        isHome = false,
        isLast = false,
        ...rest
    } = props

    const classNameProps = {
        className: classNames(
            themeColor && 'text-beaconMain-600',
            className
        ),
    }

    const handleClick = () => {
      window.location.reload(false)
    }

    const content = (
        <Button
            variant="solid"
            color={isLast ? 'beaconActive-600' : 'beaconMain-1300'}
            className={(!isLast ? 'opacity-50' : 'opacity-100') + ' font-thin flex gap-1 items-center'}
            onClick={handleClick}
            size='sm'
        >
            {isHome ? <HomeRegularIcon /> : ''}
            {children}
            {!isLast ? <ArrowRightIcon /> : ''}
        </Button>
    )

    return to ? (
        <Link to={to} {...classNameProps} {...rest}>
            {content}
        </Link>
    ) : (
        <a href={href} {...classNameProps} {...rest}>
            {content}
        </a>
    )
}

BreadcrumbItem.propTypes = {
    themeColor: PropTypes.bool,
    to: PropTypes.string,
}

BreadcrumbItem.defaultProps = {
    themeColor: true,
}

export default BreadcrumbItem
