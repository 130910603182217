import { webApi } from '../config';

class AuthApi {
  login = (body) => webApi().post('/auth/login/', body, { formData: false });
  refresh = (body) => webApi().post('/auth/refresh/', body, { formData: true });
  logout = () => webApi().post('/auth/logout/');

  register = (body) => webApi().post('/auth/register/', body);
  verify = (body) => webApi().post('/auth/register/confirm/', body);

  forgotPassword = (body) => webApi().post('/auth/reset/password/', body);
  resetPassword = (body) => webApi().post('./auth/reset/password/confirm', body);
}

export const authApi = new AuthApi();
