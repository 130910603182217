import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    first_name: "",
    last_name: "",
    id: 0,
    username: "user@example.com",
    phone_number: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip_code: "",
    authority: [],
    powerbi_items: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions
export const currentUser = (state) => state.auth.user;
export default userSlice.reducer
