export const powerbi_items = [
    {
        menu: 'Home',
        value: 'home',
        label: 'Highgate',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiYTMxNzkxZGYtY2JhYy00MTk0LWFkNGYtYjJhOWUyZDkzMzJjIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Endpoints/Assets',
        value: 'endpoints_assets',
        label: 'Highgate Endpoints - Pg.1',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiYTMxNzkxZGYtY2JhYy00MTk0LWFkNGYtYjJhOWUyZDkzMzJjIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Endpoints/Software',
        value: 'endpoints_software',
        label: 'Highgate Endpoints (Software) - Pg.2',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiMzliMTY5NDctYjBkZC00MDg2LWIyZmUtZTgxN2FiYmFjNWYzIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: '',
        value: 'vulnerability',
        label: 'Vulnerability',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiNTkyZjYyZTAtY2JjMi00N2UyLTljOTgtODc3ZWMwMzUzODQ5IiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Vulnerability/Internal Scans',
        value: 'internal_scans',
        label: 'Highgate V-Scans (Internal) - Pg.5',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiZDk4YjcwNWQtOTIyMy00NmYzLTgwNzAtODllZTE1Y2YxYjdmIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Vulnerability/External Scans',
        value: 'external_scans',
        label: 'Highgate V-Scans (External) - Pg.4',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiNmUzY2Q4YmEtN2I1ZS00MDYxLWI5ZjctZDkyOGVkNmJlNDMxIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'CrowdStrike',
        value: 'crowdstrike',
        label: 'Highgate MSSP (CrowdStrike) - Pg.7',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiZWY3NmJkMGEtZTJhYS00YmU4LWEyZGMtM2EzZjI0Y2VlZGFiIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Alerts',
        value: 'alerts',
        label: 'Highgate MSSP (Alerts) - Pg.6',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiYWRjYzI2ODQtYmQ3Yi00YWEyLThhZTUtYzY0NTgzZTcwMDhiIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
    {
        menu: 'Phishing',
        value: 'phishing',
        label: 'Highgate Phishing - Pg.7',
        pbiSrc: '',
    },
    {
        menu: 'AD Scans',
        value: 'ad_scans',
        label: 'Highgate AD Scans - Pg.8',
        pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiN2EzMGE5MDUtNDlmYi00OTU4LWJhZDktYjA0MzYwNTE5M2U3IiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
    },
]
