export const ArrowRightIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.68891 20.67C9.49891 20.67 9.30891 20.6 9.15891 20.45C8.86891 20.16 8.86891 19.68 9.15891 19.39L15.6789 12.87C16.1589 12.39 16.1589 11.61 15.6789 11.13L9.15891 4.60999C8.86891 4.31999 8.86891 3.83999 9.15891 3.54999C9.44891 3.25999 9.92891 3.25999 10.2189 3.54999L16.7389 10.07C17.2489 10.58 17.5389 11.27 17.5389 12C17.5389 12.73 17.2589 13.42 16.7389 13.93L10.2189 20.45C10.0689 20.59 9.87891 20.67 9.68891 20.67Z"
                fill="white"
                fillOpacity="0.5"
            />
        </svg>
    )
}
