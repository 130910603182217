import { createSlice } from '@reduxjs/toolkit'
import { powerbi_items } from './highgate'

export const initialState = {
    powerbi_items: powerbi_items,
    data: {
        item: {
            data: [
                {
                    menu: 'Home',
                    value: 'highgate2',
                    label: 'Highgate',
                    pbiSrc: 'https://app.powerbi.com/view?r=eyJrIjoiZTI3Y2MxYjMtY2E2Yi00ODk2LWE1YTYtZjUxMzRjNDRhNTNjIiwidCI6IjdmMzVjMDdkLTFlZjItNGVlOC1iZDZkLWY4ZDg3M2QyODc4NiIsImMiOjF9',
                },
            ],
        },
    },
    layouts: {
        lg: [{ w: 13, h: 5, x: 0, y: 0, i: 'item' }],
        md: [{ w: 12, h: 5, x: 0, y: 0, i: 'item' }],
        sm: [{ w: 12, h: 5, x: 0, y: 0, i: 'item' }],
        xs: [{ w: 12, h: 5, x: 0, y: 0, i: 'item' }],
    },
    breakpoint: 'lg',
}

export const commonSlice = createSlice({
    name: 'dynamic/common',
    initialState,
    reducers: {
        setPowerBIData: (state, action) => {
            state.powerbi_items = action.payload
        },
    },
})

export const { setPowerBIData } = commonSlice.actions

export default commonSlice.reducer
