import { webApi } from '../config';

class ProfileApi {
  getMe = () => webApi({ auth: true }).get('/profile/');
  updateMe = (body) => webApi({ auth: true }).put(`/profile/`, body);
  deleteMe = () => webApi({ auth: true }).delete(`/profile/`);

  updateMeEmail = (body) => webApi({ auth: true }).post(`/profile/update/email/`, body);
  updateMePassword = (body) => webApi({ auth: true }).post(`/profile/update/password/`, body);
}

export const profileApi = new ProfileApi();
