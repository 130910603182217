export const HomeRegularIcon = () => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.1635 5.11362L11.6048 1.44837C10.833 0.834294 9.87585 0.5 8.88958 0.5C7.90331 0.5 6.94617 0.834294 6.17439 1.44837L1.61564 5.11362C1.10986 5.51811 0.701895 6.03153 0.422156 6.61564C0.142418 7.19974 -0.00187976 7.83947 1.84885e-05 8.4871V13.9503C0.00194983 15.0979 0.459001 16.1978 1.2709 17.0089C2.0828 17.8199 3.18324 18.2758 4.33083 18.2766H6.83814V13.9503C6.83814 13.4062 7.05428 12.8844 7.439 12.4997C7.82372 12.1149 8.3455 11.8989 8.88958 11.8989C9.43365 11.8989 9.95544 12.1149 10.3402 12.4997C10.7249 12.8844 10.941 13.4062 10.941 13.9503V18.2766H13.4483C14.5959 18.2758 15.6964 17.8199 16.5083 17.0089C17.3202 16.1978 17.7772 15.0979 17.7791 13.9503V8.4871C17.781 7.83947 17.6367 7.19974 17.357 6.61564C17.0773 6.03153 16.6693 5.51811 16.1635 5.11362ZM16.4115 13.9466C16.4101 14.7317 16.0973 15.484 15.5417 16.0387C14.9862 16.5934 14.2334 16.905 13.4483 16.9053H12.3086V13.9466C12.3086 13.0399 11.9484 12.1702 11.3072 11.529C10.666 10.8878 9.79637 10.5276 8.88958 10.5276C7.98279 10.5276 7.11313 10.8878 6.47193 11.529C5.83073 12.1702 5.47052 13.0399 5.47052 13.9466V16.9053H4.33083C3.54581 16.905 2.79294 16.5934 2.23742 16.0387C1.6819 15.484 1.36909 14.7317 1.36764 13.9466V8.48343C1.36665 8.0411 1.46541 7.60424 1.65657 7.20535C1.84773 6.80645 2.12638 6.4558 2.47177 6.17946L7.03052 2.51421C7.55888 2.09401 8.21404 1.86523 8.88912 1.86523C9.5642 1.86523 10.2194 2.09401 10.7477 2.51421L15.3065 6.17946C15.6519 6.4558 15.9305 6.80645 16.1217 7.20535C16.3128 7.60424 16.4116 8.0411 16.4106 8.48343V13.9466H16.4115Z"
                fill="#FFA242"
            />
        </svg>
    )
}
